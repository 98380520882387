import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';

import {mapSegnalazioni} from '../store/actions/mapAction'
import {connect} from 'react-redux'



 function MapTable({data, specie, mapSegnalazioni}) {

let label;
let codpign;
if(specie.length > 0){
  label = specie[0].genere_specie_sottospecie;
  codpign = specie[0].codpign;
}else{
  label = 'Segnalazioni'
}




  const [state, setState] = React.useState({
    columns: [
      { title: 'ABQ', field: 'abq', cellStyle: { maxWidth: 50, width:50}},
      { title: 'TIPO', field: 'tipo', cellStyle: { maxWidth: 50, width:50}},
      { title: 'LOCO', field: 'loco', cellStyle: { minWidth: 500, backgroundColor: '#fffed2'}},
      { title: 'PROVENIENZA DATI', field: 'prov_dat' },
      { title: 'UTENTE', field: 'owner' },
      { title: 'QUOTA', field: 'quota' },
      { title: 'QUOTA MAX', field: 'quota_max' },
      { title: 'EST', field: 'est_dec' },
      { title: 'NORD', field: 'nord_dec' },
      { title: 'DATA SEGNALAZIONE', field: 'data', type: 'date' },
    ],
    data: [{}]
  });



  useEffect(() => {
    loadData();
  },[]);



const loadData = async () =>{
const token = localStorage.token;
  if(token && codpign){
    let codpignok = codpign.replace(/\//g, '--');
    const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/info_segnalazioni/${codpignok}`, {
        headers: {
          Authorization: `jwt ${token}`
          }
        });
      const data = await response.json();
      setState({
        ...state,
         data,
      });
  }
}

const downloadData = () => {
  const token = localStorage.token;
    if(token && codpign){
      let codpignok = codpign.replace(/\//g, '--');
      fetch(`${process.env.REACT_APP_ENDPOINT}/api/info_segnalazioni_download/${codpignok}`, {
          headers: {
            Authorization: `jwt ${token}`
            }
          }, { responseType: 'arraybuffer' })
      .then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `lista-${codpignok}.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    });
    }
  };


  return (
    <Container className='mapTable' maxWidth="xl" >
      <button onClick={downloadData} className="download-btn"> Download Data</button>
    <MaterialTable
      title={label}
      columns={state.columns}
      data={state.data}
      options={{
          pageSize: 5,
          tableLayout:'auto',
          headerStyle: {
           color: '#1d5e55',
         }
      }}
    />
    </ Container>
  );
}


const mapDispatchToProps =(dispatch)=>{
  return{
    mapSegnalazioni: (specie)=> dispatch(mapSegnalazioni(specie)),
  }
}

const mapStateToprops=(state)=>{
  return{
    data:state.mappa.data,
    specie: state.mappa.specie
  }
}

export default connect(mapStateToprops, mapDispatchToProps)(MapTable)
