import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { postSegnalazione } from '../store/actions/tableAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

//prova form
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker,} from '@material-ui/pickers';





import { useFormik } from 'formik';
import * as Yup from 'yup';


const useStyles = makeStyles(theme => ({
  divCheck:{
    width:'50%',
    paddingLeft:30,
  },
  ulCheck:{
    listStyleType:'none',
    paddingLeft:0,
    margin:0,
    display:'flex'
  },
  liCheck:{
    paddingRight:10,
  },
  row:{
    display:"flex",
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: 0,
    marginRight: 10,
    marginBottom:20,
    width: '25%',
  },
  textFieldS: {
    marginTop: 0,
    marginRight: 10,
    marginBottom:10,
    width: '19%',
  },
  datePicker:{
    marginLeft: 20,
    marginTop: 0,
    marginRight: 20,
    width: '25%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  marginTop:{
    marginTop:0,
  },
  marginBot:{
    marginBottom:20,
    width:'100%'
  },
  button:{
    width:'50%',
    height:50,
    margin: '0 auto',
    marginTop: 50,
    marginBottom:50,
  },
  local:{
    marginLeft: 0,
    marginTop:0,
    marginRight: 0,
    width: '50%',
    maxHeight: 150
  },
  message:{
    width: '100%',
    textAlign:'center',
  },
  errorMessage:{
    color:'#e64414',
  },
  successMessage:{
    color:'#1d5e55',
  }
}));

//array
const tipo = [
  {
    value: 'EX',
    label: 'EX - estinta',
  },
  {
    value: 'HB',
    label: 'HB - erbario',
  },
  {
    value: 'OX',
    label: 'OX - osservazione',
  },
  {
    value: 'ER',
    label: 'ER - erbario storico rivisto',
  },
  {
    value: 'ES',
    label: 'ES - erbario storico non rivisto',
  },
  {
    value: 'SR',
    label: 'SR - segnalazione bibliografica recente (> 1968)',
  },
  {
    value: 'SA',
    label: 'SA - segnalazione bibliografica antica (> 1968)',
  },
];


const esposizione = [
  {
    value: null,
    label: 'Sconosciuta',
  },
  {
    value: 'N',
    label: 'N',
  },
  {
    value: 'NE',
    label: 'NE',
  },
  {
    value: 'NW',
    label: 'NW',
  },
  {
    value: 'S',
    label: 'S',
  },
  {
    value: 'SE',
    label: 'SE',
  },
  {
    value: 'SW',
    label: 'SW',
  },
  {
    value: 'W',
    label: 'W',
  },
  {
    value: 'E',
    label: 'E',
  }
];

const provincia = [
  {
    value: 'BG',
    label: 'BG',
  },
  {
    value: 'BS',
    label: 'BS',
  },
  {
    value: 'LC',
    label: 'LC',
  },
  {
    value: 'SO',
    label: 'SO',
  },
  {
    value: 'CO',
    label: 'CO',
  },
  {
    value: 'MI',
    label: 'MI',
  },
  {
    value: 'MB',
    label: 'MB',
  },
  {
    value: 'CR',
    label: 'CR',
  },
  {
    value: 'MN',
    label: 'MN',
  },
  {
    value: 'TR',
    label: 'TR',
  },
  {
    value: 'VR',
    label: 'VR',
  },
  {
    value: 'BZ',
    label: 'BZ',
  },
  {
    value: 'LO',
    label: 'LO',
  },
  {
    value: 'PC',
    label: 'PC',
  },
  {
    value: 'PR',
    label: 'PR',
  },
  {
    value: 'PV',
    label: 'PV',
  }
];

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

function InsertData(stateR) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;


  const [comuni, setComuni] = React.useState([]);

  const [regioni, setRegioni] = React.useState([]);

  const [ogu, setOgu] = React.useState([]);


  const [check, setCheck] = React.useState({
    simili: []
  });


  const checkOgu = async (codpign, ogu) =>{
    const token = localStorage.token;

    let specie;

    if(codpign !== null){
      specie = codpign;
    }else{
      specie = formik.values.codpign;
    }

    let abq;

    if(ogu !== null){
      abq = ogu;
    }else{
      abq = formik.values.abq;
    }

    let specieReplace;
      if(specie !== null){
        specieReplace = specie.replace(/\//g, '--');
      }
      console.log(specie, abq);

      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/check_segnalazioni/${specie}/${abq}/`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${token}`
        }
      }).catch((error) => {
        console.log(error)
      });;

        const data = await response.json();
        console.log(data)

        setCheck({
          simili: data
        });

  }


  React.useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/ogu/`);
      const ogu = await response.json();
      setOgu(ogu);
    })();
  }, []);


  React.useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/sottoregioni/`);
      const regioni = await response.json();
      setRegioni(regioni);
    })();
  }, []);


  React.useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/comuni/`);
      const comuni = await response.json();
      setComuni(comuni);
    })();
  }, []);

  React.useEffect(() => {
    let active = true;
    if (!loading) {  return undefined;}
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/elespe/`);
      const specie = await response.json();
      if (active) {
        setOptions(specie);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


const formik = useFormik({
    initialValues: {
      codpign: '',
      revisionata:false,
      accettata:false,
      abq: '',
      tipo: '',
      data: null,
      prov:'',
      prov_dat:'',
      esp:'',
      comune:'',
      loco:'',
      sf:'',
      quota:null,
      quota_max:null,
      est_dec:'',
      nord_dec:'',
      suolo:'',
      legit:'',
      determ:'',
      data_det:'',
      ns:null,
      ubicazione:'',
      n_inv:null,
      n_ingr_hb:null,
      segn:''
    },
    validationSchema: Yup.object({
      codpign: Yup.string()
      .required('Campo obbligatorio'),
      abq: Yup.string()
      .required('Campo obbligatorio'),
      tipo: Yup.string()
      .required('Campo obbligatorio'),
      data: Yup.date()
      .required('Campo obbligatorio').nullable(),
      prov: Yup.string()
      .required('Campo obbligatorio'),
      comune: Yup.string()
      .required('Campo obbligatorio'),
      sf: Yup.string()
     .required('Campo obbligatorio'),
      loco: Yup.string()
      .required('Campo obbligatorio'),
      est_dec: Yup.string()
      .matches(/\d\d[,]\d\d\d\d\d/, "2 cifre (virgola) 5 cifre")
      .nullable(true),
      nord_dec: Yup.string()
      .matches(/\d\d[,]\d\d\d\d\d/, "2 cifre (virgola) 5 cifre")
      .nullable(true),
    }),
    onSubmit: values => {
      const segnalazione ={
        ...values
      }
      stateR.postSegnalazione(segnalazione);
    },
  });



  return (

  <Container className='insertContainer' maxWidth="xl" >

   <Grid container spacing={1}>
    <h1 className='titolo-form'> Campi Obbligatori </h1>

    <form onSubmit={formik.handleSubmit} className={classes.form} noValidate autoComplete="off">

  <div className={classes.marginBot}>


    <Grid className={classes.row} item xs={12}>

        <Autocomplete
          id="codpign"
          required
          className={classes.textField}
          open={open}
          onOpen={() => {setOpen(true);}}
          onClose={() => {setOpen(false);}}
          getOptionSelected={(option, value) => option.codpign === value.codpign}
          getOptionLabel={option => option.codpign+' — ' +option.genere_specie_sottospecie}
          options={options}
          onChange={(e, v) =>{ if(v){
              formik.setFieldValue('codpign', v.codpign);
              checkOgu(v.codpign, null);
            }
          }
          }
          loading={loading}
          renderInput={params => (
            <TextField
              {...params}
              label="Specie"
              required
              fullWidth
              helperText={formik.touched.codpign && formik.errors.codpign ? (
                <span className='validation'>{formik.errors.codpign}</span>
              ) : null}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />


        {/* Selezione quadrante */}
        <Autocomplete
             id="quadrante"
             className={classes.textField}
             options={ogu}
             required
             getOptionLabel={(option) => option.abq +'—'+ option.nome}
             onChange={(e, v) =>{ if(v){
                  formik.setFieldValue('abq', v.abq);
                  checkOgu(null, v.abq);
                }
               }
             }
             renderInput={(params) => <TextField {...params} label="Area base - quadrante *" variant="outlined"
             helperText= {formik.touched.abq && formik.errors.abq ? (
                          <span className='validation'>{formik.errors.abq}</span>
                        ) : null} name='abq' />}
           />



           {/* Selezione tipo */}
          <TextField
            id="tipo"
            name='tipo'
            select
            label="Tipo di segnalazione"
            required
            className={classes.textField}
            value={formik.values.tipo}
            onChange={formik.handleChange}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            helperText={formik.touched.tipo && formik.errors.tipo ? (
                        <span className='validation'>{formik.errors.tipo}</span>
                      ) : null}
            margin="normal"
            variant="outlined"
          >
            {tipo.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>



          {/* Selezione data */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
               <KeyboardDatePicker
                 disableToolbar
                 variant="inline"
                 format="dd/MM/yyyy"
                 required
                 margin="normal"
                 id="data"
                 name='data'
                 label="Data"
                 helperText={formik.touched.data && formik.errors.data ? (
                              <span className='validation'>{formik.errors.data}</span>
                            ) : null}
                 className={classes.datePicker}
                 value={formik.values.data}
                 onChange={date => formik.setFieldValue('data', date)}
                 KeyboardButtonProps={{
                   'aria-label': 'change date',
                 }}
               />
           </MuiPickersUtilsProvider>



    </Grid>

    <Grid className={classes.row} item xs={12}>

            {/* provincia */}
            <TextField
              id="prov"
              name='prov'
              select
              label="Provincia"
              required
              helperText= {formik.touched.prov && formik.errors.prov ? (
                            <span className='validation'>{formik.errors.prov}</span>
                          ) : null}
              variant="outlined"
              className={classes.textField}
              value={formik.values.prov}
              onChange={formik.handleChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {provincia.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>






            {/* comune */}

            <Autocomplete
                 id="comune"
                 className={classes.textField}
                 options={comuni}
                 required

                 getOptionLabel={(option) => option.comune}
                 onChange={(e, v) =>{ if(v){
                     formik.setFieldValue('comune', v.comune)}
                   }
                 }
                 renderInput={(params) => <TextField {...params} label="comune" variant="outlined" name='comune' helperText={formik.touched.comune && formik.errors.comune ? (
                   <span className='validation'>{formik.errors.comune}</span>
                   ) : null}/>}
               />



               {/* prova regioni */}

               <Autocomplete
                    id="regione"
                    className={classes.textField}
                    options={regioni}
                    required
                    getOptionLabel={(option) => option.sf + '—' +option.nome  }
                    onChange={(e, v) =>{ if(v){
                        formik.setFieldValue('sf', v.sf)}
                      }
                    }
                    renderInput={(params) => <TextField {...params} label="sottoregioni*" variant="outlined" name='regione'   helperText={formik.touched.sf && formik.errors.sf ? (
                        <span className='validation'>{formik.errors.sf}</span>
                        ) : null}/>}
                  />




    </Grid>

    <Grid className={classes.row} item xs={12}>

      {/* località */}
      <TextField
              id="localita"
              name='loco'
              label="Località"
              multiline
              rows="4"
              required
              helperText={formik.touched.loco && formik.errors.loco ? (
                <span className='validation'>{formik.errors.loco}</span>
                ) : null}
              className={classes.local}
              value={formik.values.loco}
              onChange={formik.handleChange}
              margin="normal"
              variant="outlined"
            />




{check.simili ?(
  <div className={classes.divCheck}>
    <Typography variant='h6' color='primary' style={{"paddingLeft":"10px"}}>Segnalazioni simili</Typography>
    <div className='simili'>
    {check.simili.map(option => (
      <Typography style={{"paddingLeft":"10px"}}  key={option.tipo}>
        {option.tipo}:{option.tipo_total_species}
      </Typography>
    ))}
  </div>
  </div>
) :(null)}


    </Grid>

</div>


      <h1 className='titolo-form second-title'> Campi Facoltativi </h1>

          {/* quota minmax */}


          <TextField
            id="quota-max"
            name='quota_max'
            type='number'
            className={classes.textFieldS}
            value={formik.values.quota_max}
            onChange={formik.handleChange}
            label="quota massima"
            margin="normal"
            variant="outlined"

          />


          <TextField
            id="quota-min"
            name='quota'
            type='number'
            className={classes.textFieldS}
            value={formik.values.quota}
            onChange={formik.handleChange}
            label="quota minima"
            margin="normal"
            variant="outlined"
          />


          {/* esposizione */}
          <TextField
            id="esposizione"
            name='esp'
            select
            label="Esposizione"
            variant="outlined"
            helperText= {formik.touched.esp && formik.errors.esp ? (
                  <span className='validation'>{formik.errors.esp}</span>
                  ) : null}
            className={classes.textField}
            value={formik.values.esp}
            onChange={formik.handleChange}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            {esposizione.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>


          {/* lito */}
          <TextField
            id="lito"
            name='suolo'
            className={classes.textFieldS}
            value={formik.values.suolo}
            onChange={formik.handleChange}
            label="Lito"
            margin="normal"
            variant="outlined"
          />


          {/* coordinate */}

          <TextField
            id="latitudine"
            name='nord_dec'
            className={classes.textFieldS}
            value={formik.values.nord_dec}
            onChange={formik.handleChange}
            helperText={formik.touched.loco && formik.errors.nord_dec ? (
              <span className='validation'>{formik.errors.nord_dec}</span>
              ) : null}
            label="Latitudine (decimale)"
            margin="normal"
            variant="outlined"
          />

          <TextField
            id="longitudine"
            name='est_dec'
            className={classes.textFieldS}
            value={formik.values.est_dec}
            onChange={formik.handleChange}
            helperText={formik.touched.loco && formik.errors.est_dec ? (
              <span className='validation'>{formik.errors.est_dec}</span>
              ) : null}
            label="Longitudine (decimale)"
            margin="normal"
            variant="outlined"
          />


          <TextField
            id="prov_dat"
            name='prov_dat'
            className={classes.textFieldS}
            value={formik.values.prov_dat}
            onChange={formik.handleChange}
            label="Provenienza Dati"
            margin="normal"
            variant="outlined"
          />


        {/* erbario */}
      <div className={classes.marginTop}>
      <h1 className='titolo-form'> Dati di erbario </h1>

        <TextField
          id="legit"
          name='legit'
          className={classes.textFieldS}
          value={formik.values.legit}
          onChange={formik.handleChange}
          label="Legit"
          margin="normal"
          variant="outlined"

        />
        <TextField
          id="determ"
          name='determ'
          className={classes.textFieldS}
          value={formik.values.determ}
          onChange={formik.handleChange}
          label="Determinavit"
          margin="normal"
          variant="outlined"

        />
        <TextField
          id="data-det"
          name='data_det'
          className={classes.textFieldS}
          value={formik.values.data_det}
          onChange={formik.handleChange}
          label="Data determinavit"
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="ns"
          name='ns'
          type='number'
          className={classes.textFieldS}
          value={formik.values.ns}
          onChange={formik.handleChange}
          label="Numero Schede"
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="ubicazione"
          name='ubicazione'
          className={classes.textFieldS}
          value={formik.values.ubicazione}
          onChange={formik.handleChange}
          label="ubicazione"
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="inventario"
          name='n_inv'
          type='number'
          className={classes.textFieldS}
          value={formik.values.n_inv}
          onChange={formik.handleChange}
          label="N° inventario"
          margin="normal"
          variant="outlined"
        />



        <TextField
          id="segn"
          name='segn'
          className={classes.textFieldS}
          value={formik.values.segn}
          onChange={formik.handleChange}
          label="SEGN"
          margin="normal"
          variant="outlined"
        />

      </div>



      <div className={classes.message}>
            {(stateR.formFail) ? (  <Typography className={classes.errorMessage}
               color='primary' variant="h6" component="h2">
                {stateR.formFail}
          </Typography>) : null}
          {(stateR.formSuccess) ? (
              <Typography className={classes.successMessage}
             color='primary' variant="h6" component="h2">
              {stateR.formSuccess}
        </Typography>
      ) : null}
      </div>


        <Button type="submit" variant="contained" color="primary" className={classes.button}>
            Invio dati
        </Button>

    </form>
    </Grid>


</ Container>
  );
}

const mapStateToProps = stateR =>({
formSuccess: stateR.table.formSuccess,
formFail: stateR.table.formFail,
user: stateR.auth.user
});

export default connect(mapStateToProps,{postSegnalazione})(InsertData);
